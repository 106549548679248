import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-order-notification',
  templateUrl: './order-notification.component.html',
  styleUrls: ['./order-notification.component.css']
})
export class OrderNotificationComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  OrderId: number;

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(switchMap((params: ParamMap) => {
    const id = params.get('id');

    this.OrderId = +id; 

    return Promise.resolve();
  })).subscribe();

  }
}
