import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CartService } from '../_services/cart.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-order-completed',
  templateUrl: './order-completed.component.html',
  styleUrls: ['./order-completed.component.css']
})
export class OrderCompletedComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    public cartService: CartService,
    public authService: AuthService,
  ) { }

  OrderId: number;
  Payment: string;
  ebankUrl: string;
  EncOID: string;

  ngOnInit(): void {
    this.ebankUrl = environment.ebankUrl;
    this.activatedRoute.paramMap.pipe(switchMap((params: ParamMap) => {
      const id = params.get('id');
      this.OrderId = +id; 
      this.EncOID = btoa(id);

      const payment = params.get('payment');
      this.Payment = payment; 

      return Promise.resolve();
    })).subscribe();

    this.authService
      .login()
      .then((res) => {
        this.cartService.complete(this.OrderId).subscribe(
          async (authRes) => {
            if (authRes) {
              // send oms and email notification of order details
              return Promise.resolve(authRes);
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
      }
    );

    

  }
}
