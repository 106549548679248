import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Globals } from '../globals';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-order-cancelled',
  templateUrl: './order-cancelled.component.html',
  styleUrls: ['./order-cancelled.component.css']
})
export class OrderCancelledComponent implements OnInit {

  public vendorName = new FormControl();
  
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public globals: Globals) {}
  
  ngOnInit(): void {
  }

}
