import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cart } from './../_models/cart';
import { Product } from '../_models/product';
import { CartItem } from '../_models/cart-item';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Tools } from '../_shared/tools';
import { Globals } from '../globals';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class CartService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    public globals: Globals,
    public tools: Tools,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  addToCart(product: Product): Promise<any> {
    const cart = this.globals.Store.Customer.Cart;
    const item = _.find(cart.Items, (o) => o.Product.Id === product.Id) as CartItem;

    if (item) {
      return this.cartItemUpdate(cart.Id, item.Id, product.Id, item.Quantity)
        .then((res) => {
          item.Quantity += 1;
          this.globals.cartItemsCount.next(true);
          const snackbarRef = this.showMessage('1 item has been updated to your cart', 'Go to cart');
          snackbarRef.afterDismissed().subscribe((resp) => {
            if (resp.dismissedByAction) {
              this.globals.goToUrl(this.router, 'cart');
            }
          });
          return Promise.resolve(res);
        })
        .then((res) => {
          return Promise.resolve(res);
        });
    } else {
      return this.cartItemAdd(cart.Id, product.Id, 1)
        .then((newItem) => {
          newItem.Product = product;
          cart.Items.push(newItem);
          this.globals.cartItemsCount.next(true);
          const snackbarRef = this.showMessage('1 new item has been added to your cart', 'Go to cart');
          snackbarRef.afterDismissed().subscribe((resp) => {
            if (resp.dismissedByAction) {
              this.globals.goToUrl(this.router, 'cart');
            }
          });
        })
        .then((res) => {
          return Promise.resolve(res);
        });
    }
  }

  removeFromCart(ref: number): Promise<any> {
    return this.cartItemDelete(ref).then((res) => {
      _.remove(this.globals.Store.Customer.Cart.Items, { Id: ref });
      this.globals.cartItemsCount.next(true);
      this.showMessage('1 item has been removed from your cart', 'Removed');
      return Promise.resolve(res);
    });
  }

  cartUpdate(cart: Cart): Promise<any> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.carts, cart.Id]);
    return this.http.put(requestUrl, cart, options).toPromise();
  }

  cartItemAdd(cartId: string, productId: number, qty: number): Promise<CartItem> {
    const obj = {
      CartId: cartId,
      ProductId: productId,
      Quantity: qty,
    };

    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.cartItems]);
    return this.http
      .post(requestUrl, obj, options)
      .toPromise()
      .then((res) => res as CartItem);
  }

  cartItemUpdate(cartId: string, id: number, productId: number, qty: number): Promise<any> {
    if (id <= 0) {
      return new Promise((resolve) => {
        resolve(void 0);
      });
    }

    const obj = {
      Id: id,
      CartId: cartId,
      ProductId: productId,
      Quantity: qty,
    };

    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.cartItems, id]);
    return this.http.put(requestUrl, obj, options).toPromise();
  }

  cartItemDelete(id: number) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.cartItems, id]);
    return this.http.delete(requestUrl, options).toPromise();
  }

  checkout(cart: Cart): Observable<number> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.carts, 'checkout', cart.Id]);
    return this.http.post<number>(requestUrl, cart, options);
  }

  complete(id: number): Observable<number> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.carts, 'completed', id]);
    console.log(this.auth.token + ' TOOOOOOKKKKKEEEENNNN');
    return this.http.post<number>(requestUrl, id, options);
  }

  getShippingFee(cartId: string): Promise<number> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.carts, 'sf', cartId]);
    return this.http
      .get(requestUrl, options)
      .toPromise()
      .then((res) => res as number);
  }

  private showMessage(message, action) {
    return this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
